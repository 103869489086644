import React, { useEffect, useState } from "react";
import { getVenddorRegister } from "../../redux/homeAction";
import "./vendorRegistrationForm.scss";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const VendorRegistrationForm = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [terms, setTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;
  var numberRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const onInputHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitVendorRegisterion = (e) => {
    e.preventDefault();
    const data = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      email: values?.email,
      phone: values?.mobile,
      password: values?.password,
      store_name: values?.shopName,
      store_url: values?.shopUrl,
      store_location: values?.location,
    };

    if (!values?.firstName) {
      toast.error("Enter First Name");
    } else if (!values?.lastName) {
      toast.error("Enter Last Name");
    } else if (!values?.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(values?.email)) {
      toast.error("Invalid Email Address");
    } else if (!values?.mobile) {
      toast.error("Enter Phone Number");
    } else if (!numberRegex.test(values.mobile)) {
      toast.error("Please enter a valid phone number");
    } else if (!values?.password) {
      toast.error("Enter Password");
    } else if (!strongRegex.test(values?.password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else if (!values?.location) {
      toast.error("Enter Shop Location");
    } else if (!values?.shopName) {
      toast.error("Enter Shop Name");
    } else if (terms === false) {
      toast.error("Please accept terms & conditions");
    } else {
      dispatch(getVenddorRegister(data, setValues));
    }
  };

  return (
    <>
      <div className="vendor-bg">
        <div className="container">
          <div className="vendor-wrapper">
            <div className="vendor-leftsideimgwrappr">
              <img src="/img/vendorform1.png" alt="#" className="img-fluid" />
            </div>
            <div className="vendor-rightsideform-wrapper">
              <form onSubmit={submitVendorRegisterion}>
                <div className="vendor-headingDiv">
                  <h5 className="vendorHeading">Vendor Registration </h5>
                  <a
                    href="https://smileszy.ng/dashboard/login"
                    target="blank"
                    className="vendorLogin-button"
                  >
                    Login
                  </a>
                </div>
                <div className="vendorformwrapp">
                  <div className="imputdiv-wrapper">
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      className="firstname-input"
                      onChange={(e) => onInputHandler(e)}
                    />

                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      className="firstname-input"
                      onChange={(e) => onInputHandler(e)}
                    />
                  </div>
                  <input
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    value={values.email}
                    className="email-inputwrapp"
                    onChange={(e) => onInputHandler(e)}
                  />

                  <input
                    type="number"
                    placeholder="Phone Number"
                    name="mobile"
                    value={values.mobile}
                    className="email-inputwrapp"
                    onChange={(e) => onInputHandler(e)}
                  />
                  <div className="password-input ">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      className="email-inputwrapp"
                      onChange={(e) => onInputHandler(e)}
                    />
                    {showPassword === false ? (
                      <i
                        class="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={() => setShowPassword(true)}
                      ></i>
                    ) : (
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        onClick={() => setShowPassword(false)}
                      ></i>
                    )}
                  </div>

                  <input
                    type="text"
                    placeholder="Shop location"
                    name="location"
                    value={values.location}
                    className="email-inputwrapp"
                    onChange={(e) => onInputHandler(e)}
                  />
                  <div className="imputdiv-wrapper">
                    <input
                      type="text"
                      name="shopName"
                      placeholder="Shop Name"
                      value={values.shopName}
                      className="firstname-input"
                      onChange={(e) => onInputHandler(e)}
                    />

                    <input
                      type="text"
                      placeholder="Shop URL"
                      name="shopUrl"
                      value={values.shopUrl}
                      className="firstname-input"
                      onChange={(e) => onInputHandler(e)}
                    />
                  </div>

                  <div className="checkboxlines">
                    <input
                      id="terms"
                      type="checkbox"
                      onClick={() => {
                        setTerms(!terms);
                      }}
                      checked={terms}
                      className="me-2"
                    />{" "}
                    <span>I have read and agree to the terms & condition</span>
                  </div>
                  <button className="Ragistr-btn" type="submit">
                    Register Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorRegistrationForm;
